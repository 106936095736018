import {
  mapGetters
} from 'vuex'
import {
  remoteLoad,
  ubiTags,
  bd_encrypt
} from "@/common/utils";
import {
  getDirectionDescription
} from '@/common/dictionary'
import throttle from '@/common/throttle'
import mixinMapCachePosition from '@/views/mixins/mixin-map-cache-position'
import { getDefaultMap } from '@/common/cache'
import ArrowButton from "@/components/arrow-button";
import miment from "miment";
import {GetWrapper, GetTextView} from '@/views/online/google-map.js'
export default {
  components: {
    ArrowButton
  },
  ...mapGetters([
    'currentMap',
    'hasMenu'
  ]),
  data() {
    return {
      map: null,
      miment,
      infoWindow: null,
      infoContent: null,
      SimpleMaker: null,
      homeMarker: null,
      homeCircle: null,
      iconSize: 38,
      isBlodLine: true,
      speedRange: [30, 60],
      lineColors: ['#DE0000', '#087EC4', '#33B100'],
      beginOverLays: [],
      homeOverLays: [],
      pointOverLays: [],
      startOverLays:[],
      lineOverLays:[],
      table: {
        show: false,
        pageIndex: 1,
        pageSize: 10,
        isWrap: false,
        viewTable: 1,
        height: 224
      },
    }
  },
  methods: {
    initMap(center = [116.397428, 39.90923], zoom = 11) {
      return new Promise(async (resolve) => {
        if(process.env.VUE_APP_PLAT === '_kc'){
          const position = this.loadPosition('google') // mixin
          center = position.center
          zoom = position.zoom

          if (!window.google){
            await remoteLoad(this.$GOOGLE_URL_EN)
          }

          let map = new window.google.maps.Map(this.$refs.container, {
            center: { lng: center[0],lat: center[1] },
            zoom: zoom,
            mapTypeControlOptions: {
              position: window.google.maps.ControlPosition.TOP_RIGHT
            }
          })

          this.map = map
          this.google = window.google

          // 获得Wrapper变量
          this.Wrapper = GetWrapper(window.google)
        }else{

          const position = this.loadPosition(); // mixin
          let center = position.center || [116.397428, 39.90923];
          let zoom = position.zoom || 11;
          let currentMap = getDefaultMap()
          switch(currentMap) {
            case 'Amap':  
            if (!window.AMap) {
              await remoteLoad(this.$AMAP_URL)
            }
            if (!window.AMapUI) {
              await remoteLoad('//webapi.amap.com/ui/1.0/main.js?v=1.0.11')
            }
            if (!this.SimpleMaker) {
              await this.initSimpleMarker()
            }
              let map = new window.AMap.Map(this.$refs.container, {
                resizeEnable: true,
                zoom,
                center,
                zooms: [3,20],
                expandZoomRange: true
              })
              window.AMap.plugin('AMap.GraspRoad', () => {
                this.graspRoad = new window.AMap.GraspRoad()
              })
              window.AMapUI.loadUI(['control/BasicControl'], BasicControl => {
                map.addControl(new BasicControl.LayerSwitcher({
                  position: {
                    top: '50px',
                    right: '10px'
                  }
                }))
              })
              this.map = map
              this.AMap = window.AMap
              this.AMapUI = window.AMapUI
              this.ifNotExistsWindow()
              // this.ifNotExistsWindowSimple()
              break;
            case 'Bmap':
              if (!window.BMap) {
                window.BMap_loadScriptTime = (new Date).getTime()
                window.BMAP_PROTOCOL = window.location.protocol.replace(':', '')
                await remoteLoad(this.$BMAP_URL)
              }
  
              if (!window.BMapLib) {
                await remoteLoad('//api.map.baidu.com/library/DistanceTool/1.2/src/DistanceTool_min.js')
                await remoteLoad('//api.map.baidu.com/library/DrawingManager/1.4/src/DrawingManager_min.js')
                await remoteLoad('//api.map.baidu.com/library/MarkerClusterer/1.2/src/MarkerClusterer_min.js')
                await remoteLoad('//api.map.baidu.com/library/TextIconOverlay/1.2/src/TextIconOverlay_min.js')
              }
  
              let map2 = new window.BMap.Map(this.$refs.container, { enableMapClick: false })
              map2.centerAndZoom(new window.BMap.Point(center[0], center[1]), zoom)
              map2.enableScrollWheelZoom(true)
              map2.enableContinuousZoom()
              map2.clearHotspots()
  
              let mapType = new window.BMap.MapTypeControl(
                {
                  mapTypes: [window.BMAP_NORMAL_MAP, window.BMAP_HYBRID_MAP],
                  anchor: window.BMAP_ANCHOR_TOP_RIGHT
                }
              )
              map2.addControl(mapType)
  
              this.map = map2
              this.BMap = window.BMap
  
              // 清空地图
              this.BMap.Map.prototype.clearMap = this.BMap.Map.prototype.clearOverlays
              this.BMap.Map.prototype.on = this.BMap.Map.prototype.addEventListener
              this.BMap.Map.prototype.off = this.BMap.Map.prototype.removeEventListener
              // 关闭信息窗体
              this.BMap.Map.prototype.clearInfoWindow = this.BMap.Map.prototype.closeInfoWindow
              // 打开信息窗体
              this.BMap.InfoWindow.prototype.open = function (m, p) {
                p = p instanceof Array ? new window.BMap.Point(p[0], p[1]) : p
                m.openInfoWindow(this, p)
              }
              // 关闭信息窗体
              this.BMap.InfoWindow.prototype.close = () => this.map.closeInfoWindow()
              // 信息窗体设置位置
              this.BMap.InfoWindow.prototype.setPosition = function (p) {
                p = p instanceof Array ? new window.BMap.Point(p[0], p[1]) : p
                this.isOpen() && map.openInfoWindow(this, p)
              }
              this.BMap.InfoWindow.prototype.getIsOpen = this.BMap.InfoWindow.prototype.isOpen
              // Overlay属性
              this.BMap.Overlay.prototype.setMap = function(m) { m ? m.addOverlay(this) : (this.getMap() && this.getMap().removeOverlay(this))}
              this.BMap.Overlay.prototype.on = this.BMap.Overlay.prototype.addEventListener
              this.BMap.Overlay.prototype.off = this.BMap.Overlay.prototype.removeEventListener
              this.BMap.Overlay.prototype.getExtData = function () { return this.extData }
              this.BMap.Overlay.prototype.setExtData = function (v) { this.extData = v }
              // Point属性
              this.BMap.Point.prototype.getLng = function () { return this.lng }
              this.BMap.Point.prototype.getLat = function () { return this.lat }
  
              this.ifNotExistsWindow()
  
            break;
            case 'QQmap':
              if (!window.AMap) {
                await remoteLoad(this.$AMAP_URL)
              }
  
              if (!window.AMapUI) {
                await remoteLoad('//webapi.amap.com/ui/1.0/main.js?v=1.0.11')
              }
  
              if (!this.SimpleMaker) {
                await this.initSimpleMarker()
              }
  
              // 初始化地图
              // console.log('api load success, init map', new Date())
              var layer = new window.AMap.TileLayer({
                getTileUrl(x, y, z) {
                  let ty = Math.pow(2, z) - 1 - y
                  return `//p3.map.gtimg.com/maptilesv2/${z}/${Math.floor(x/16.0)}/${Math.floor(ty/16.0)}/${x}_${ty}.png?version=20190101`
                },
                zIndex:2
              });
  
              this.mapLayer = layer
  
              // 初始化地图
              // console.log('api load success, init map', new Date())
              let map3 = new window.AMap.Map(this.$refs.container, {
                resizeEnable: true,
                zoom,
                center,
                isHotspot: false,
                features: [],
                preloadMode: false,
                zooms: [3,20],
                layers: [layer],
                expandZoomRange: true
              })
              // 加载插件
              // map.addControl(new window.AMap.Scale({
              //   offset: new window.AMap.Pixel(10, 10),
              //   position: 'LT'
              // }))
              window.AMap.plugin('AMap.GraspRoad', () => {
                this.graspRoad = new window.AMap.GraspRoad()
              })
              window.AMapUI.loadUI(['control/BasicControl'], BasicControl => {
                map3.addControl(new BasicControl.LayerSwitcher({
                  position: {
                    top: '50px',
                    right: '10px'
                  }
                }))
              })
              this.map = map3
              this.AMap = window.AMap
              this.AMapUI = window.AMapUI
              this.ifNotExistsWindow()
              // this.ifNotExistsWindowSimple()
            break;
            case 'Google':
              const position = this.loadPosition('google') // mixin
              center = position.center
              zoom = position.zoom
    
              if (!window.google){
                await remoteLoad(this.$GOOGLE_URL_EN)
              }
              let maxLat = Math.atan(Math.sinh(Math.PI)) * 180 / Math.PI;
              let map4 = new window.google.maps.Map(this.$refs.container, {
                center: { lng: center[0],lat: center[1] },
                zoom: zoom,
                mapTypeControlOptions: {
                  position: window.google.maps.ControlPosition.TOP_RIGHT
                },
                restriction: {
                  latLngBounds: {north: maxLat, south: -maxLat, west: -180, east: 180},
                  strictBounds: true
                },
              })
    
              this.map = map4
              this.google = window.google
    
              // 获得Wrapper变量
              this.Wrapper = GetWrapper(window.google)
              this.ifNotExistsWindow()
            break;
            // case '"Google"':
            //   console.log('google')
            //   if (!window.AMap) {
            //     await remoteLoad(this.$AMAP_URL)
            //   }
  
            //   if (!window.AMapUI) {
            //     await remoteLoad('//webapi.amap.com/ui/1.0/main.js?v=1.0.11')
            //   }
  
            //   if (!this.SimpleMaker) {
            //     await this.initSimpleMarker()
            //   }
  
            //   // 初始化地图
            //   // console.log('api load success, init map', new Date())
            //   var layer = new window.AMap.TileLayer({
            //     getTileUrl: this.getLayerTile,
            //     zIndex:2
            //   });
  
            //   this.mapLayer = layer
  
            //   // 初始化地图
            //   // console.log('api load success, init map', new Date())
            //   let map4 = new window.AMap.Map(this.$refs.container, {
            //     resizeEnable: true,
            //     zoom,
            //     center,
            //     isHotspot: false,
            //     features: [],
            //     preloadMode: false,
            //     zooms: [3,20],
            //     layers: [layer],
            //     expandZoomRange: true
            //   })
            //   // 加载插件
            //   // map.addControl(new window.AMap.Scale({
            //   //   offset: new window.AMap.Pixel(10, 10),
            //   //   position: 'LT'
            //   // }))
            //   window.AMap.plugin('AMap.GraspRoad', () => {
            //     this.graspRoad = new window.AMap.GraspRoad()
            //   })
            //   this.map = map4
            //   this.AMap = window.AMap
            //   this.AMapUI = window.AMapUI
            //   this.ifNotExistsWindow()
            //   // this.ifNotExistsWindowSimple()
  
            // break;
            case 'Openstreet':
              if (!window.AMap) {
                await remoteLoad(this.$AMAP_URL)
              }
  
              if (!window.AMapUI) {
                await remoteLoad('//webapi.amap.com/ui/1.0/main.js?v=1.0.11')
              }
  
              if (!this.SimpleMaker) {
                await this.initSimpleMarker()
              }
  
              var layer = new window.AMap.TileLayer({
                getTileUrl: this.getLayerTile,
                zIndex:2
              });
  
              this.mapLayer = layer
  
              // 初始化地图
              // console.log('api load success, init map', new Date())
              let map5 = new window.AMap.Map(this.$refs.container, {
                resizeEnable: true,
                zoom,
                center,
                isHotspot: false,
                features: [],
                preloadMode: false,
                layers: [layer]
              })
              // 加载插件
              // map.addControl(new window.AMap.Scale({
              //   offset: new window.AMap.Pixel(10, 10),
              //   position: 'LT'
              // }))
              window.AMap.plugin('AMap.GraspRoad', () => {
                this.graspRoad = new window.AMap.GraspRoad()
              })
              this.map = map5
              this.AMap = window.AMap
              this.AMapUI = window.AMapUI
              this.ifNotExistsWindow()
              // this.ifNotExistsWindowSimple()
  
            break;
            case 'Bing':
              if (!window.AMap) {
                await remoteLoad(this.$AMAP_URL)
              }
  
              if (!window.AMapUI) {
                await remoteLoad('//webapi.amap.com/ui/1.0/main.js?v=1.0.11')
              }
  
              if (!this.SimpleMaker) {
                await this.initSimpleMarker()
              }
  
              // 初始化地图
              // console.log('api load success, init map', new Date())
              var layer = new window.AMap.TileLayer({
                getTileUrl:(x, y, z) => {
                  let code = new Array(z)
                  let nex = Math.pow(2, z-1)
                  for (let i = 0; i < code.length; i++) {
                    let ox = Math.floor(x / nex) % 2
                    let oy = (Math.floor(y / nex) % 2) * 2
                    code[i] = ox + oy
                    nex = nex / 2
                  }
                  let imgNumber = code.join('')
                  return `https://t1.dynamic.tiles.ditu.live.com/comp/ch/${imgNumber}?mkt=${this.$i18n.locale=="zh" ? "zh-CN": "en-US"}&ur=CN&it=G,TW,L&n=z&og=5&cstl=rd`
                },
  
                zIndex:2
              });
  
              this.mapLayer = layer
  
              // 初始化地图
              // console.log('api load success, init map', new Date())
              let map6 = new window.AMap.Map(this.$refs.container, {
                resizeEnable: true,
                zoom,
                center,
                isHotspot: false,
                features: [],
                preloadMode: false,
                zooms: [3,18],
                layers: [layer],
                expandZoomRange: false
              })
              // 加载插件
              // map.addControl(new window.AMap.Scale({
              //   offset: new window.AMap.Pixel(10, 10),
              //   position: 'LT'
              // }))
              window.AMap.plugin('AMap.GraspRoad', () => {
                this.graspRoad = new window.AMap.GraspRoad()
              })
              window.AMapUI.loadUI(['control/BasicControl'], BasicControl => {
                map6.addControl(new BasicControl.LayerSwitcher({
                  position: {
                    top: '50px',
                    right: '10px'
                  }
                }))
              })
              this.map = map6
              this.AMap = window.AMap
              this.AMapUI = window.AMapUI
              this.ifNotExistsWindow()
              // this.ifNotExistsWindowSimple()
  
            break;
            default:
                console.log('c')
          } 



        }
       
        resolve();
      });
    },
    initSimpleMarker() {
      const promise = new Promise((resolve) => {
        window.AMapUI.loadUI(['overlay/SimpleMarker'], SimpleMaker => {
          this.SimpleMaker = SimpleMaker
          resolve()
        })
      })
      return promise
    },
    ifNotExistsWindow() {
      if (!this.infoWindow) {
        if (window.BMap && (this.map instanceof window.BMap.Map)) {
          let infoContent = this.$dataPanel.getInstance()
          let infoWindow = new this.BMap.InfoWindow('', {
            width: 0,
            height: 0,
            offset: new this.BMap.Size(0, -42),
            enableCloseOnClick: false,
            enableAutoPan: false
          })

          // 地图
          infoWindow.map = this.map

          // 根据getId获取当前监控车辆
          infoWindow.setId = id => infoWindow._id = id
          infoWindow.getId = () => infoWindow._id
          infoWindow.setContent(infoContent.$mount().$el)
          this.infoWindow = infoWindow
          this.infoContent = infoContent
          this.infoWindow.setContent = () => {}
        }else if(window.google){
          let infoWindow = new this.Wrapper(
            this.map,
            new this.google.maps.LatLng(0, 0),
            { el: document.createElement('div'), offsetY: -35 },
            null)
  
          this.infoWindow = infoWindow
      
        } else {
          let infoContent = this.$dataPanel.getInstance()
          let infoWindow = new this.AMap.InfoWindow({
            isCustom: true,
            content: infoContent.$mount().$el,
            offset: new this.AMap.Pixel(0, -42)
          })
          // 根据getId获取当前监控车辆
          infoWindow.setId = id => infoWindow._id = id
          infoWindow.getId = () => infoWindow._id
          infoWindow.on('close', () => {
            this.isTrackInfoOpen = false
          })
          this.infoWindow = infoWindow
          this.infoContent = infoContent
          this.infoWindow.setContent = () => {}
        }
      }
    },
    startDrag() {
      this.infoWindow.close()
    },
    getPanelGoogle(device) {
      let panel = this.$dataPanel.create({
        bgColor: true,
        title: this.group.vehicle.VehicleNum,
        datas: [
          {title: `${this.$t('onlineTrack.rcvt')}`, text: miment(device.RcvTime).format()},
          {title: `${this.$t('onlineTrack.gpst')}`, text: miment(device.GPSTime).format()},
          {title: `${this.$t('onlineTrack.speed')}`, text: this.$t('onlineTrack.speedDesc', {speed:device.Speed})},
          {title: `${this.$t('onlineTrack.direction')}`, text: getDirectionDescription(device.Direct, this.$i18n.locale)},
          {title: `${this.$t('onlineTrack.mile')}`, text: device.Mileage + this.$t('onlineTrack.kilo')},
          {title: `${this.$t('onlineTrack.oil')}`, text: device.OilNum + this.$t('onlineTrack.oilUnit')},
          {title: `${this.$t('onlineTrack.status')}`, text: device.StatusDes}
        ],
        showAddrDefault: this.showAddress,
        paddingLeft: '4em',
        showMore: false,
        titleBackground: '#409EFF',
        close: true,
        externalTag: false,
        lon: device.Lon,
        lat: device.Lat,
        //visible: this.panel_open,
        onClose: () => {
          this.infoWindow.close()
        },
        onChange: () => {}
      })
      return panel
    },
    // ifNotExistsWindow() {
    //   if (!this.infoWindow) {
    //     let infoWindow = new this.Wrapper(
    //       this.map,
    //       new this.google.maps.LatLng(0, 0),
    //       { el: document.createElement('div'), offsetY: -35 },
    //       null)

    //     this.infoWindow = infoWindow
    //   }
    // },
    addBeginMarker(row) {
      this.removeBeginMarker()
      if (window.BMap && (this.map instanceof window.BMap.Map)) {
        let startMarker = new this.BMap.Marker(
          new this.BMap.Point(row.Lon, row.Lat), {
            rotation: this.getIconAngle(row.VehicleImageUrl, row.Direct),
            icon: new this.BMap.Icon(
              require(`../../assets/mapIcons/${row.VehicleImageUrl}`),
              new this.BMap.Size(32, 32), {
                anchor: new this.BMap.Size(16, 32)
              })
          })
        startMarker.setMap(this.map)
        this.beginOverLays.push(startMarker)
        this.setFitViews(this.map, this.beginOverLays)
        startMarker.on('click', () => {
          this.infoWindow.setContent(this.getPanelImmediate(row))
          this.infoWindow.open(this.map, startMarker.getPosition())
        })
      } else {
        let startMarker = new this.AMap.Marker({
          map: this.map,
          icon: require(`../../assets/mapIcons/${row.VehicleImageUrl}`),
          position: [row.Lon, row.Lat],
          offset: new this.AMap.Pixel(-16, -32),
          angle: this.getIconAngle(row.VehicleImageUrl,row.Direct),
        })
        this.beginOverLays.push(startMarker)
        this.map.setFitView()
        startMarker.on('click', () => {
          this.infoWindow.setContent(this.getPanelImmediate(row))
          this.infoWindow.open(this.map, startMarker.getPosition())
          this.map.setCenter([row.Lon, row.Lat]);
        })

      }
    },
    addBeginMarkerKC(row) {
      let startMarker = new this.google.maps.Marker({
        position: { lng: row.Lon, lat: row.Lat},
        label: '',
        map: this.map,
        icon: {
          url: require(`../../assets/mapIcons/${row.VehicleImageUrlEN}`),
          anchor: new this.google.maps.Point(24, 24),
          size:new google.maps.Size(24, 24),
          scaledSize: new google.maps.Size(24, 24), 
        },
        zIndex:2
      })
      this.beginOverLays.push(startMarker)
      startMarker.addListener('click', () => {
        this.infoWindow.setContent(this.getPanelGoogle(row))
        this.infoWindow.setPosition(startMarker.getPosition())
        this.infoWindow.setId(startMarker.extID)
        this.infoWindow.show()
        // this.markerClick(marker, data)
      })
      this.map.setCenter(new this.google.maps.LatLng(row.Lat, row.Lon))
      this.map.setZoom(18)
    },
    removeBeginMarker() {
      this.beginOverLays.forEach(k => {
        k.setMap(null)
        k = null
      })
      this.beginOverLays.splice(0)
    },
    addRealMarker(data) {
      this.removeBeginMarker()
      this.removedRealMarker()
      if (window.BMap && (this.map instanceof window.BMap.Map)) {
        let startMarker = new this.BMap.Marker(
          new this.BMap.Point(data.Lon, data.Lat), {
            icon: new this.BMap.Icon(
              require(`../../assets/icon/map-marker-green.png`),
              new this.BMap.Size(32, 32), {
                anchor: new this.BMap.Size(16, 32)
              })
          })
        startMarker.setMap(this.map)
        startMarker.on('click', () => {
          this.infoWindow.setContent(this.getPanelImmediate(data))
          this.infoWindow.open(this.map, startMarker.getPosition())
        })
        this.startOverLays.push(startMarker)
        this.setFitViews(this.map, this.startOverLays)
        let marker__point = new this.BMap.Marker(new this.BMap.Point(data.Lon, data.Lat), {
          rotation: this.getIconAngle(data.VehicleImageUrl, data.Direct),
          icon: new this.BMap.Icon(require(`../../assets/mapIcons/${data.VehicleImageUrl}`),
            new this.BMap.Size(24, 24), {
              anchor: new this.BMap.Size(12, 12),
              imageSize: new this.BMap.Size(24, 24)
            })
        })
        marker__point.setZIndex(2)
        marker__point.setMap(this.map)
        this.isTrackInfoOpen = true
        marker__point.on('click', () => {
          this.infoWindow.setContent(this.getPanelImmediate(data))
          this.infoWindow.open(this.map, marker__point.getPosition())
        })
        this.marker__point = marker__point
        this.startOverLays.push(marker__point)
      } else {

        let startMarker = new this.AMap.Marker({
          map: this.map,
          icon: require(`../../assets/icon/map-marker-green.png`),
          position: [data.Lon, data.Lat],
          offset: new this.AMap.Pixel(-16, -32)
        })

        startMarker.on('click', () => {
          this.infoWindow.setContent(this.getPanelImmediate(data))
          this.infoWindow.open(this.map, startMarker.getPosition())
        })
        this.startOverLays.push(startMarker)
        this.map.setFitView()
        let marker__point = new this.AMap.Marker({
          map: this.map,
          icon: new window.AMap.Icon({
            size: new window.AMap.Size(this.iconSize, this.iconSize),
            image: require(`../../assets/mapIcons/${data.VehicleImageUrl}`),
            imageSize: new window.AMap.Size(this.iconSize, this.iconSize)
          }),
          position: [data.Lon, data.Lat],
          offset: new this.AMap.Pixel(-this.iconSize / 2, -this.iconSize),
          angle: this.getIconAngle(data.VehicleImageUrl,data.Direct),
          extData: 0,
          zIndex: 101
        })
        this.isTrackInfoOpen = true
        marker__point.on('click', () => {
          this.infoWindow.setContent(this.getPanelImmediate(data))
          this.infoWindow.open(this.map, marker__point.getPosition())
        })

        this.marker__point = marker__point
        this.startOverLays.push(marker__point)
      }
    },
    addRealMarkerKC(data){
      this.removeBeginMarker()
      this.removedRealMarker()
      let startMarker = new this.google.maps.Marker({
        position: { lng: data.Lon, lat: data.Lat},
        label: '',
        map: this.map,
        icon: {
          url: require(`../../assets/icon/map-marker-green.png`),
          anchor: new this.google.maps.Point(24, 24),
          size:new google.maps.Size(32, 32),
          scaledSize: new google.maps.Size(32, 32),
        },
        zIndex:1
      })
      this.startOverLays.push(startMarker)
      
      startMarker.addListener('click', () => {
        this.infoWindow.setContent(this.getPanelGoogle(data))
        this.infoWindow.setPosition(startMarker.getPosition())
        this.infoWindow.setId(startMarker.extID)
        this.infoWindow.show()
        // this.markerClick(marker, data)
      })

      let marker__point = new this.google.maps.Marker({
        position: { lng: data.Lon, lat: data.Lat},
        label: '',
        map: this.map,
        icon: {
          url: require(`../../assets/mapIcons/${data.VehicleImageUrlEN}`),
          anchor: new this.google.maps.Point(24, 24),
          size:new google.maps.Size(24, 24),
          scaledSize: new google.maps.Size(24, 24), 
        },
      })
      this.isTrackInfoOpen = true

      marker__point.addListener('click', () => {
        this.infoWindow.setContent(this.getPanelGoogle(data))
        this.infoWindow.setPosition(marker__point.getPosition())
        this.infoWindow.setId(marker__point.extID)
        this.infoWindow.show()
        // this.markerClick(marker, data)
      })
      this.marker__point = marker__point
      this.startOverLays.push(marker__point)
    },
    removedRealMarker() {
      this.startOverLays.forEach(k => {
        k.setMap(null)
        k = null
      })
      this.startOverLays.splice(0)
    },
    getIconAngle(imageUrl, angle) {
      if (imageUrl.indexOf('Alarm') != -1
      || imageUrl.indexOf('Personal') != -1
      || imageUrl.indexOf('hat') != -1
      || imageUrl.indexOf('RV') != -1
      || imageUrl.indexOf('Fifth Wheel') != -1
      || imageUrl.indexOf('person') != -1
      || imageUrl.indexOf('article') != -1
      || imageUrl.indexOf('Trailer') != -1
      || imageUrl.indexOf('child') != -1
      || imageUrl.indexOf('youth') != -1
      || imageUrl.indexOf('middle-aged') != -1
      || imageUrl.indexOf('old-man') != -1
      || imageUrl.indexOf('other') != -1
      || imageUrl.indexOf('child1') != -1
      || imageUrl.indexOf('youth1') != -1
      || imageUrl.indexOf('middle-aged1') != -1
      || imageUrl.indexOf('old-man1') != -1
      ||imageUrl.indexOf('Moto') != -1 
      // || imageUrl.indexOf('other1') != -1
      || imageUrl.indexOf('child0') != -1
      || imageUrl.indexOf('youth0') != -1
      || imageUrl.indexOf('middle-aged0') != -1
      || imageUrl.indexOf('old-man0') != -1
      // || imageUrl.indexOf('other0') != -1
      || imageUrl.indexOf('cuboid') != -1
      || imageUrl.indexOf('round-ellipse') != -1
      || imageUrl.indexOf('irregular-form') != -1
      || imageUrl.indexOf('Marine') != -1) {
        return 0
      }
      return angle || 0
    },
    addHomeMarker() {
      // homeOverLays
      if (this.group.people && this.group.people.HomeLon && this.group.people.HomeLat) {
        let lon = this.group.people.HomeLon
        let lat = this.group.people.HomeLat
        let marker = new this.SimpleMaker({
          iconLabel: {
            innerHTML: `<img src="${require('../../assets/icon/home.svg')}" style="height:24px;width:24px;background:#FFF;border-radius:50%;padding:2px;transform:translateY(6px);">`
          },
          iconTheme: 'fresh',
          iconStyle: 'red',
          map: this.map,
          position: [lon, lat]
        })
        marker.on('click', () => {
          this.infoWindow.setContent(this.getSimplePanel(this.$t('onlineTrack.homeAddress'), [], '6em', [lon, lat]))
          this.infoWindow.open(this.map, marker.getPosition())
        })
        this.homeMarker = marker
        this.homCircle = new this.AMap.Circle({
          map: this.map,
          strokeColor: '#409EFF',
          strokeWeight: 1,
          fillColor: 'rgba(0,0,255,1)',
          fillOpacity: 0.1,
          center: [lon, lat],
          radius: 400
        })
        this.map.add(this.homeMarker)
        this.map.add(this.homCircle)
      }
      if (this.group.people && this.group.people.CompanyLon && this.group.people.CompanyLat) {
        let lon = this.group.people.CompanyLon
        let lat = this.group.people.CompanyLat
        let marker = new this.SimpleMaker({
          iconLabel: {
            innerHTML: `<img src="${require('../../assets/icon/department.svg')}" style="height:24px;width:24px;background:#FFF;border-radius:50%;padding:2px;transform:translateY(6px);">`
          },
          iconTheme: 'fresh',
          iconStyle: 'red',
          map: this.map,
          position: [lon, lat]
        })

        marker.on('click', () => {
          this.infoWindow.setContent(this.getSimplePanel(this.$t('onlineTrack.companyAddress'), [], '6em', [lon, lat]))
          this.infoWindow.open(this.map, marker.getPosition())
        })
        this.companyMarker = marker
        this.companyCircle = new this.AMap.Circle({
          map: this.map,
          strokeColor: '#409EFF',
          strokeWeight: 1,
          fillColor: 'rgba(0,0,255,1)',
          fillOpacity: 0.1,
          center: [lon, lat],
          radius: 400
        })
        this.map.add(this.companyCircle)
        this.map.add(this.companyMarker)
      }
    },
    removeHomeMarker() {
      if (this.homeMarker) {
        // this.homeMarker.setMap(null)
        this.map.remove(this.homeMarker)
        this.homeMarker = null
        this.homeCircle = null
        this.map.remove(this.homCircle)
      }
      if (this.companyMarker) {
        // this.companyMarker.setMap(null)
        this.map.remove(this.companyMarker)
        this.companyMarker = null
        this.map.remove(this.companyCircle);
        this.companyCircle = null
      }
    },
    addPoint(point) {
      if (window.BMap && (this.map instanceof window.BMap.Map)) {
        let marker = new this.BMap.Marker(new this.BMap.Point(point.Lon, point.Lat), {
          icon: new this.BMap.Icon(require(`../../assets/icon/TrackPointRun.gif`),
            new this.BMap.Size(9, 9), {
              anchor: new this.BMap.Size(4, 4)
            })
        })
        marker.setMap(this.map)
        marker.on('click', () => {
          this.infoWindow.setContent(this.getPanelImmediate(point))
          this.infoWindow.open(this.map, [point.Lon, point.Lat])
        })
        this.pointOverLays.push(marker)
      } else {
        let marker = new this.AMap.Marker({
          map: this.map,
          icon: require(`../../assets/icon/TrackPointRun.gif`),
          position: [point.Lon, point.Lat],
          offset: new this.AMap.Pixel(-4, -4)
        })
        marker.on('click', () => {
          this.infoWindow.setContent(this.getPanelImmediate(point))
          this.infoWindow.open(this.map, [point.Lon, point.Lat])
        })
        this.pointOverLays.push(marker)
      }
    },
    clearPoint() {
      this.pointOverLays.forEach(k => {
        k.setMap(null)
        k = null
      })
      this.pointOverLays.splice(0)
    },
    addPointKC(point){
      let marker = new this.google.maps.Marker({
        position: { lng: point.Lon, lat: point.Lat},
        label: '',
        map: this.map,
        icon: {
          url: require(`../../assets/icon/TrackPointRun.gif`),
          anchor: new this.google.maps.Point(12, 12),
          size:new google.maps.Size(12, 12),
          scaledSize: new google.maps.Size(12, 12), 
        },
      })
      marker.addListener('click', () =>  {
        this.infoWindow.setContent(this.getPanelGoogle(point))
        this.infoWindow.setPosition(marker.getPosition())
        this.infoWindow.setId(marker.extID)
        this.infoWindow.show()
      })
      this.pointOverLays.push(marker)
    },
    markerMove(d){
      if (window.BMap && (this.map instanceof window.BMap.Map)){
        this.marker__point.setPosition(new this.BMap.Point(d.Lon, d.Lat))
        this.marker__point.setRotation(this.getIconAngle(d.VehicleImageUrl,d.Direct))
        // this.marker__point.setExtData(beginIndex)
        this.marker__point.setIcon(new this.BMap.Icon(require(`../../assets/mapIcons/${d.VehicleImageUrl}`),
            new this.BMap.Size(24, 24),
            {
              anchor: new this.BMap.Size(12, 12),
              imageSize: new this.BMap.Size(24, 24)
            }))
      }else{
        this.marker__point.setPosition([d.Lon,d.Lat])
        this.marker__point.setAngle(this.getIconAngle(d.VehicleImageUrl,d.Direct))
        // this.marker__point.setExtData(beginIndex)
        this.marker__point.setIcon(new window.AMap.Icon({
          size: new window.AMap.Size(this.iconSize, this.iconSize),
          image: require(`../../assets/mapIcons/${d.VehicleImageUrl}`),
          imageSize: new window.AMap.Size(this.iconSize, this.iconSize)
        }))
        // this.map.setCenter([d.Lon, d.Lat])
        this.map.setCenter(this.marker__point.getPosition())
      }
      this.isTrackInfoOpen = true
      if (this.isTrackInfoOpen) {
        this.infoWindow.setContent(this.getPanelImmediate(d))
        this.infoWindow.open(this.map, this.marker__point.getPosition())
      }
    },
    markerMoveKC(d){
      this.marker__point.setIcon({
        url: require(`../../assets/mapIcons/${d.VehicleImageUrlEN}`),
        anchor: new this.google.maps.Point(24, 24),
        size:new google.maps.Size(24, 24),
        scaledSize: new google.maps.Size(24, 24),
      })
      this.marker__point.setZIndex(3)
      this.marker__point.setPosition({ lng: d.Lon, lat: d.Lat})
      this.map.setCenter(new this.google.maps.LatLng(d.Lat, d.Lon))
      if (this.map.getZoom() < 14) {
          this.map.setZoom(12)
      }
      this.isTrackInfoOpen = true
      if (this.isTrackInfoOpen) {
        this.infoWindow.setContent(this.getPanelGoogle(d))
        this.infoWindow.open(this.map, this.marker__point.getPosition())
      }
    },
    addPath(pPoint,nPoint){
      pPoint.speedMode = this.isLowSpeed(pPoint, nPoint) ? 'low' : (this.isHighSpeed(pPoint, nPoint) ? 'high' : 'normal')
        let color = pPoint.speedMode == 'low' ? this.lineColors[0] : (pPoint.speedMode == 'high' ? this.lineColors[2] : this.lineColors[1])
      if (window.BMap && (this.map instanceof window.BMap.Map)){
        let polyline = new this.BMap.Polyline([pPoint, nPoint].map(k => new this.BMap.Point(k.Lon, k.Lat)),
        {
          strokeOpacity: 1,
          strokeColor: color,
          strokeWeight: this.isBlodLine ? 7 : 3,
        })
        polyline.setMap(this.map)
        this.lineOverLays.push(polyline)
      }else{
        let paths = [pPoint, nPoint].map((k) =>[k.Lon, k.Lat])
        let polyline = new this.AMap.Polyline({
          map: this.map,
          borderWeight: 0,
          strokeColor: color,
          strokeWeight: this.isBlodLine ? 7 : 3,
          lineJoin: 'round',
          showDir: this.isBlodLine,
          path: paths
        })
        this.lineOverLays.push(polyline)
      }
    },
    addPathKC(pPoint,nPoint){
      pPoint.speedMode = this.isLowSpeed(pPoint, nPoint) ? 'low' : (this.isHighSpeed(pPoint, nPoint) ? 'high' : 'normal')
      let color = pPoint.speedMode == 'low' ? this.lineColors[0] : (pPoint.speedMode == 'high' ? this.lineColors[2] : this.lineColors[1])
      let paths = [pPoint, nPoint].map(k => new this.google.maps.LatLng(k.Lat, k.Lon))
      // path: linePoint.map(k => new this.google.maps.LatLng(k.Lat, k.Lon))

      let polyline = new this.google.maps.Polyline({
        map: this.map,
        //borderWeight: 0,
        strokeColor: color,
        strokeWeight: this.isBlodLine ? 7 : 3,
        //lineJoin: 'round',
        //showDir: this.setting.isBlodLine,
        path:paths
      })
      this.lineOverLays.push(polyline)
    },
    getSimplePanel(title, datas, padding, position) {
      Object.assign(this.infoContent, {
        bgColor: true,
        title: title,
        datas,
        paddingLeft: padding,
        showMore: false,
        showHeader: false,
        close: true,
        lon: position[0],
        lat: position[1],
        //visible: this.panel_open,
        onClose: () => {
          this.map.clearInfoWindow()
        },
        onChange: () => {}
      })
    },
    setFitViews(map, markers) {
      const points = []
      markers.forEach(k => {
        if (k.getPosition) {
          points.push(k.getPosition())
        } else if (k.getPath) {
          k.getPath().forEach(j => points.push(j))
        }
      })
      if (points.length) {
        this.map.setViewport(points)
      }
    },
    getPanelImmediate(device) {
      Object.assign(this.infoContent, {
        bgColor: true,
        title: this.group.vehicle.VehicleNum,
        datas: [
          {title: `${this.$t('onlineTrack.rcvt')}`, text: miment(device.RcvTime).format()},
          {title: `${this.$t('onlineTrack.gpst')}`, text: miment(device.GPSTime).format()},
          {title: `${this.$t('onlineTrack.speed')}`, text: this.$t('onlineTrack.speedDesc', {speed:device.Speed})},
          {title: `${this.$t('onlineTrack.direction')}`, text: getDirectionDescription(device.Direct, this.$i18n.locale)},
          {title: `${this.$t('onlineTrack.mile')}`, text: device.Mileage + this.$t('onlineTrack.kilo')},
          {title: `${this.$t('onlineTrack.oil')}`, text: device.OilNum + this.$t('onlineTrack.oilUnit')},
          {title: `${this.$t('onlineTrack.status')}`, text: device.StatusDes}
        ],
        showAddrDefault: this.showAddress,
        paddingLeft: '4em',
        showMore: false,
        titleBackground: '#409EFF',
        close: true,
        externalTag: false,
        lon: device.sLon,
        lat: device.sLat,
        //visible: this.panel_open,
        onClose: () => {
          if (window.google){
            this.infoWindow.close()
          }else{
            this.map.clearInfoWindow()
          }
          
        },
        onChange: () => {}
      })
    },
    isLowSpeed(point, nextPoint) {
      if(nextPoint){
        return nextPoint.Speed < parseInt(this.speedRange[0])
      }else{
        return true
      }
      
    },

    isHighSpeed(point, nextPoint) {
      return nextPoint.Speed > parseInt(this.speedRange[1])
    },
    addTempMarker(row) {
      this.removeTempMarker();
      let startMarker = new this.AMap.Marker({
        map: this.map,
        icon: require(`../../assets/icon/shadow_marker.png`),
        position: [row.Lon, row.Lat],
        zIndex: 102,
        offset: new this.AMap.Pixel(-16, -30),
      });
      this.tempOverLays.push(startMarker);
      startMarker.on("click", () => {
        this.infoWindow.setContent(this.getPanel(row));
        this.infoWindow.open(this.map, startMarker.getPosition());
        this.map.setCenter([row.Lon, row.Lat]);
      });
      if (this.mode === PAUSE) {
        this.infoWindow.setContent(this.getPanel(row));
        this.infoWindow.open(this.map, [row.Lon, row.Lat]);
        this.currentRow = row;
        this.map.setCenter([row.Lon, row.Lat]);
      }
    },
    removeTempMarker() {
      this.tempOverLays.forEach((k) => {
        k.setMap(null);
        k = null;
      });
      this.tempOverLays.splice(0);
    },
  },
  create() {
    this.initMap().then(() => {
      
    });
  },


  beforeDestroy() {
    clearTimeout(this.objInterval)
    if (this.map && this.map.destroy) {
      this.map.destroy()
    }
  },
  mixins: [mixinMapCachePosition]
}
