<template>
  <div class="tracing-wrap">
    <div class="playTool">
      <div class="v-name">
        {{$t('onlineTrack.realTracking')}}: <span>{{ group.vehicle.VehicleName }} -</span><span
          v-for="item in group.devices" :key="item.ObjectID">
          <i v-if="item.ObjectID === objectId">
            {{ item.SIM2 }}【{{
              item.TrackerType == 0 ? $t("dict")["有线"] : $t("dict")["无线"]
            }}】</i>
        </span>
      </div>
      <!-- <div class="v-setting">
        <span class="mgr15 total">{{ $t("onlineTrack.miles") }}：{{ totalMile || 0 }} KM</span>
        <span v-tooltip.top="$t('onlineTrack.play')" class="mgr15 t-btn"><i-icon key="play" name="icon-bofang" @click="playStart"></i-icon></span>
        <span v-tooltip.top="$t('onlineTrack.stop')" class="mgr15 t-btn"><i-icon name="icon-tingzhi1"></i-icon></span>

        <el-checkbox v-model="showHomeAndCompanyPos" class="mgr15">{{
          $t("onlineTrack.homeAndCom")
        }}</el-checkbox>
        <el-checkbox v-model="showPoint" :size="size" class="mgr15">{{
          $t("onlineTrack.showPoint")
        }}</el-checkbox>
        <el-checkbox v-model="isTrackInfoOpen" class="mgr15">{{
          $t("onlineTrack.showInfo")
        }}</el-checkbox>
        <el-select
          v-model="playRealTimeInterval"
          placeholder="刷新间隔"
          :size="size"
          class="mgr15"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div> -->
    </div>
    <div class="play-map">
      <div class="countdown">
        <i class="el-icon-time" style="margin-right:4px;"></i>
        <span>{{$t('onlineMonitor.refreshTip', { count:clockNum })}}</span>
        <!-- <el-button @click="refreshNow" type="text" :disabled="isLoadingMapData || refreshBtnBusy">{{$t('common.refresh')}}</el-button>
          <el-button @click="end_timer" v-if="isShowPause && countdown_timer" :disabled="isLoadingMapData || refreshBtnBusy" type="text">{{$t('onlineMonitor.refreshAction')}}</el-button>
          <el-button @click="start_timer" v-if="isShowPause && !countdown_timer" :disabled="isLoadingMapData || refreshBtnBusy" type="text">{{$t('onlineMonitor.refreshResume')}}</el-button> -->
      </div>
      <div class="map-content" ref="container"></div>
    </div>
    <div class="detail-table">
      <arrow-button @click="showDetail = !showDetail" class="toggleTable" :position="showDetail ? 'bottom' : 'top'">
      </arrow-button>
      <div class="c" v-show="showDetail">
         <drag-wrapper @end-drag="({y}) => table.height -= y" @start-drag="startDrag"></drag-wrapper>
        <el-table @sort-change="sortChange" class="sortable tracktable" stripe size="mini" :data="pageData"
          :height="table.height" @row-click="tableRowClick" highlight-current-row @current-change="tableRowClick" border
          style="width: 100%">
          <el-table-column prop="current" :label="$t('onlineTrack.orderNumber')" width="50"></el-table-column>
          <el-table-column prop="Speed" :label="$t('onlineTrack.speed')" width="60"></el-table-column>
          <el-table-column prop="Mileage" :label="$t('onlineTrack.miles')" width="100"></el-table-column>
          <el-table-column prop="OilNum" :label="$t('onlineTrack.oil')" width="50"></el-table-column>
          <el-table-column prop="StatusDes" :label="$t('onlineTrack.statusDes')" width="250"
            :show-overflow-tooltip="!isWrap"></el-table-column>
          <el-table-column prop="GPSTime" :label="$t('onlineTrack.gpsTime')" width="140" sortable="custom"
            :sort-orders="['descending', 'ascending']">
            <p slot-scope="{ row }">{{ miment(row.GPSTime).format() }}</p>
          </el-table-column>
          <el-table-column prop="RcvTime" :label="$t('onlineTrack.rcvTime')" width="140" sortable="custom"
            :sort-orders="['descending', 'ascending']">
            <p slot-scope="{ row }">{{ miment(row.RcvTime).format() }}</p>
          </el-table-column>
          <el-table-column prop="GPSFlag" :label="$t('onlineTrack.isGps')" width="75">
            <p slot-scope="{ row }" :title="row.GPSFlag">
              {{
                row.GPSFlag && row.GPSFlag % 2 === 1
                  ? $t("onlineTrack.yes")
                  : ""
              }}
            </p>
          </el-table-column>
          <el-table-column :label="$t('onlineTrack.place')" :show-overflow-tooltip="!isWrap" :min-width="320">
            <template slot-scope="{ row }">
              {{row.address}}
              <!-- <span-address
                :lon="row.Lon"
                :lat="row.Lat"
                @click.stop.prevent
                :key="row.index"
                :show-default="true"
              ></span-address> -->
            </template>
          </el-table-column>
        </el-table>
        <el-pagination style="margin: 0; padding: 2px 4px" small class="v-pager" :current-page.sync="table.pageIndex"
          :page-size.sync="table.pageSize" :page-sizes="table.pageSizes"
          layout="total, sizes, jumper, prev, pager, next">
        </el-pagination>
        <div class="table-check">
          <el-button type="text" @click="exportTrackData" :loading="exportTrackDataBtnLoading" :disabled="!itemId">
            {{ $t("onlineTrack.exportData") }}</el-button>
          <el-checkbox v-model="isWrap">{{
            $t("onlineTrack.wrap")
          }}</el-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  const mixinOnlineTracking = require("@/views/mixins/mixin-tracing.js").default;
  import {
    GetAddress
  } from '@/api/common'
  import {
    QueryTrackDataRealTime,
    QueryDeviceOnlineDataByObjectIDs,
    ExportTrackDataFilter,
  } from "@/api/common";
  import DragWrapper from '@/components/drag-wrapper'
  import {
    QueryVehicleComplexByObjectID
  } from "@/api/vehicle";
  import {
    saveBlob
  } from '@/common/utils'
  import miment from "miment";
  export default {
    name: "mapTracking",
    props: {
      itemId: {
        type: Number,
        default: null,
      },
    },
    components: {
      DragWrapper,
    },
    data() {
      return {
        objectId: null,
        size: "mini",
        isTrackInfoOpen: true,
        showHomeAndCompanyPos: false,
        showPoint: true,
        showDetail: true,
        isWrap: false,
        exportTrackDataBtnLoading: false,
        playRealTimeInterval: 10,
        clockNum: 0,
        objInterval: null,
        totalMile: 0,
        startTime: '',
        endTime: '',
        group: {
          vehicle: {},
          people: {},
          devices: [],
          holdInfo: {},
        },
        options: [{
            value: 10,
            label: "10s",
          },
          {
            value: 30,
            label: "30s",
          },
          {
            value: 60,
            label: "60s",
          },
          {
            value: 120,
            label: "120s",
          },
        ],
        trackDataList: [],
        table: {
          pageSizes: [50, 100, 200, 500],
          pageSize: 100,
          pageIndex: 1,
          height: 224,
        },
      };
    },
    computed: {
      pageData() {
        let sortData = this.trackDataList;
        // if (this.sortDirect && this.sortKey == "GPSTime") {
        //   sortData = sortData.sort((a, b) =>
        //     this.sortDirect == "ascending"
        //       ? a["GPSTime"] > b["GPSTime"]
        //         ? 1
        //         : a["RcvTime"] >= b["RcvTime"]
        //         ? 1
        //         : -1
        //       : a["GPSTime"] > b["GPSTime"]
        //       ? -1
        //       : a["RcvTime"] >= b["RcvTime"]
        //       ? -1
        //       : 1
        //   );
        // } else if (this.sortDirect && this.sortKey == "RcvTime") {
        //   sortData = sortData.sort((a, b) =>
        //     this.sortDirect == "ascending"
        //       ? a["RcvTime"] > b["RcvTime"]
        //         ? 1
        //         : a["GPSTime"] >= b["GPSTime"]
        //         ? 1
        //         : -1
        //       : a["RcvTime"] > b["RcvTime"]
        //       ? -1
        //       : a["GPSTime"] >= b["GPSTime"]
        //       ? -1
        //       : 1
        //   );
        // }
        let start = (this.table.pageIndex - 1) * this.table.pageSize;
        let totalSlice = this.table.pageSize * this.table.pageIndex;
        const partList = sortData.slice(start, totalSlice);
        return partList;
      },
      realScene() {
        return this.isShowRealScene && this.mode !== STOP;
      },
    },
    watch: {
      itemId(val) {
        this.objectId = val;
        // console.log(val);
      },
      objectId(val, oldVal) {
        if (val != oldVal) {
          // console.log("1");
        }
        this.$router.replace(`/online/tracing?id=${val}`);
      },
      $route(val, oldVal) {
        console.log(val)
        if (val.path === '/online/tracing') {
          console.log('enter')
        } else {
          clearTimeout(this.objInterval)
        }
        // console.log(val);
      },
    },
    methods: {
      tableRowClick(row) {
        this.addTempMarker(row);
      },
      sortChange({
        prop,
        order
      }) {
        this.sortKey = prop;
        this.sortDirect = order;
      },
      async fetchAddress(Lon, Lat) {
        const promise = new Promise((resolve, reject) => {
          GetAddress(Lon, Lat)
            .then((ret) => {
              if (ret.data.errCode) {
                this.$message({
                  message: ret.data.errMsg,
                  type: "error",
                });
                return reject(ret.data);
              }
              let result = ret.data.address;
              resolve(result);
            })
            .catch((err) => {
              reject(err);
            })
            .finally(() => {});
        });
        return promise;
      },
      fetchDeviceAddress() {
        const promise = new Promise((resolve, reject) => {
          if (!this.itemId) {
            return false;
          }
          let arr = [];
          arr.push(this.objectId);
          QueryDeviceOnlineDataByObjectIDs(arr)
            .then((ret) => {
              if (ret.data.errCode) {
                this.$message({
                  message: ret.data.errMsg,
                  type: "error",
                });
                return reject(ret.data);
              }
              let result = ret.data;
              resolve(result[0]);
            })
            .catch((err) => {
              reject(err);
            })
            .finally(() => {});
        });
        return promise;
      },
      // fetchData() {
      //   const promise = new Promise((resolve) => {
      //     if (!this.itemId) {
      //       this.group.vehicle = {};
      //       return;
      //     }
      //     QueryVehicleComplexByObjectID(this.itemId).then((ret) => {
      //       this.group = ret.data;
      //       resolve();
      //     });
      //   });
      //   return promise;
      // },
       fetchData() {
        const promise = new Promise((resolve) => {
          if (!this.itemId) {
            this.group.vehicle = {}
            return
          }
          QueryVehicleComplexByObjectID(this.itemId).then(ret => {
            this.group.vehicle = ret.data.devices[0]
            this.group.devices = ret.data.devices
            this.group.holdInfo = ret.data.holdInfo
            this.group.people = ret.data.people
            resolve()
          })
        })
        return promise
      },
      fetchRealData() {
        const promise = new Promise((resolve) => {
          QueryTrackDataRealTime(this.objectId).then((ret) => {
            resolve(ret.data.list);
          });
        });
        return promise;
      },
      async playStart() {
        const data = await this.fetchRealData();
        if (!data || data.length === 0 || !data[0].Lon) {
          this.$message(this.$t("common.noResults"));
          return false;
        }
        this.table.pageIndex = 1;
        this.totalMile = 0;
        this.clearPoint();
        data[0].address = await this.fetchAddress(data[0].sLon, data[0].sLat)
        data[0].current = 0
        data[0].currentMileage = 0
        if (window.google) {
          this.addRealMarkerKC(data[0]);
        }else{
          this.addRealMarker(data[0]);
        }
        if (window.google) {
          this.showPoint && this.addPointKC(data[0]);
        }else{
          this.showPoint && this.addPoint(data[0]);
        }
        this.trackDataList.push(data[0])
        this.playMove(0);
      },
      playMove(index) {
        clearTimeout(this.objInterval)
        this.clockNum = this.playRealTimeInterval + 1
        let timerHanlder = async () => {
          if (this.clockNum <= 1) {
            this.clockNum = this.playRealTimeInterval + 1
            timerHanlder()
            const data = await this.fetchRealData();
            if (data[0].RcvTime == this.trackDataList[0].RcvTime) {
              console.log('没有新的数据')
            } else {
              data[0].address = await this.fetchAddress(data[0].sLon, data[0].sLat)
              data[0].current = this.trackDataList[0].current + 1
              this.trackDataList.unshift(data[0])
            }
            let len = this.trackDataList.length
            data[0].currentMileage = (data[0].Mileage - this.trackDataList[len - 1].Mileage).toFixed(1)
            this.pathChange(data[0])
          } else {
            this.clockNum = this.clockNum - 1
            this.objInterval = setTimeout(timerHanlder, 1000);
          }
        }
        timerHanlder()
      },
      pathChange(d) {
        if (window.google) {
          this.markerMoveKC(d)
          this.showPoint && this.addPointKC(d);
        }else{
          this.markerMove(d)
          this.showPoint && this.addPoint(d);
        }
        let len = this.trackDataList.length
        this.totalMile = (this.trackDataList[0].Mileage - this.trackDataList[len - 1].Mileage).toFixed(1)
        this.playRate = 100
        // 画线
        let pPoint = this.trackDataList[1]
        let nPoint = this.trackDataList[0]
        if (window.google) {
          this.addPathKC(pPoint, nPoint)
        }else{
          this.addPath(pPoint, nPoint)
        }
        

       
        // if (!this.map.getBounds().contains(this.marker__point.getPosition())) {
        //   this.map.setCenter(this.marker__point.getPosition())
        // }
      },
      exportTrackData() {
        let sTime = miment(this.startTime);
        let eTime = miment(new Date());
        this.exportTrackDataBtnLoading = true;
        ExportTrackDataFilter(
            this.objectId,
            sTime,
            eTime,
            false,
            false,
            true
          )
          .then((ret) => {
            saveBlob(
              ret.data,
              this.$t("onlineTrack.exportFileName", {
                name: this.group.vehicle.VehicleName,
                start: miment(sTime).format("YYYYMMDDhhmm"),
                end: miment(eTime).format("YYYYMMDDhhmm"),
              })
            );
          })
          .finally(() => {
            this.exportTrackDataBtnLoading = false;
          });
      },
    },
    created() {
      this.objectId = this.itemId;
      this.startTime = new Date()
      this.fetchData().then(() => {
        //
      });
    },
    mounted() {
      this.initMap().then(() => {
        this.fetchDeviceAddress().then((res) => {
          if (window.google) {
            this.addBeginMarkerKC(res);
            this.playStart()
          } else {
            this.addBeginMarker(res);
            this.addHomeMarker()
            this.playStart()
          }

        });
      });
    },
    mixins: [mixinOnlineTracking],
  };

</script>
<style lang="scss" scoped>
  .mgr15 {
    margin-right: 15px;
  }

  .tracing-wrap {
    position: relative;
    z-index: 1;

    .playTool {
      width: 600px;
      text-align: center;
      font-size: 14px;
      // display: flex;
      // flex-wrap: wrap;
      // justify-content: space-between;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      padding: 10px 15px;
      position: absolute;
      left: 50%;
      margin-left: -300px;
      top: 0;
      z-index: 2;
      box-shadow: 0 0 10px #ccc;
      background-color: #fff;

      .v-name {
        line-height: 30px;
        cursor: pointer;

        span {
          color: #cc3333;

          i {
            font-style: normal;
          }
        }
      }

      .v-setting {
        height: 30px;

        .total {
          padding-right: 40px;
        }

        .t-btn {
          font-size: 22px;
        }
      }
    }

    .play-map {
      width: 100%;
      height: calc(100vh - 52px);
      position: relative;
      z-index: 1;

      .map-content {
        width: 100%;
        height: 100%;
      }

      .countdown {
        position: absolute;
        left: 15px;
        top: 60px;
        z-index: 3;
        min-width: 120px;
        padding: 5px 12px;
        background-color: #fff;
        color: #607d8b;
        user-select: none;
        border-radius: 5px;

        .countdown__number {
          color: #c33;
          margin-right: 2px;
        }

        span {
          color: #c33;
          margin-right: 2px;
          font-size: 14px;
        }
      }
    }

    .detail-table {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      background-color: #fff;

      .toggleTable {
        position: absolute;
        left: 50%;
        top: -16px;
        transition: all 0.3s ease;
        transform: translateX(-50%) translateY(0);
        z-index: 3;
        font-size: 56px;
        cursor: default;
      }

      .v-pager {
        text-align: right;
        background-color: #f4f4f8;
        padding: 0;
        color: #303133;
        font-weight: 700;
        white-space: nowrap;
      }

      .table-check {
        position: absolute;
        z-index: 111;
        right: 10px;
        top: 0;

        .el-button {
          margin-right: 12px;
          font-size: 13px;
        }

        .el-checkbox ::v-deep span {
          font-size: 13px;
        }
      }
    }
  }

</style>
